import React, { SyntheticEvent, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { push } from 'redux-first-router'
import _debounce from 'lodash/debounce'
import _map from 'lodash/map'
import BackgroundImage from 'features/background-image'
import { getQuery, getPathname,  getLocationPayload } from 'lib/location/selectors'
import ArticlesSearch from 'queries/ArticlesSearchByTitleContent'
import { HeadingLarge, TaglineLarge, HeadingSmall } from 'layouts/typography'
import { Section, Container, Button, Frame, Heading } from 'layouts'
import Pagination from '../../../articles-category/components/pagination'
// import Slider from './slider'
// import FeaturedPosts from './featured-posts'
import { Card, CardFeatured } from '../cards'
import style from './SearchResult.module.scss'

interface Props {
    query: string,
    language: string,
    // hasContent: boolean
}

const SearchResult = ({ language, query }: Props) => {
    // const addHistory = pushToHistory
    const [inputString, setInputString] = useState('')
    const [pageCount, setPageCount] = useState(0)
    const [pagingCurrentPage, setpagingCurrentPage] = useState(0)
    const location = useSelector((state) => getLocationPayload(state))
    const pagingQuery = useSelector((state) => getQuery(state))
     
    const pagingItemsPerPage = 12
    let pagingItemsToSkip = 0

    let pagingCurrent = 0

    if(pagingQuery) {
        pagingCurrent = pagingQuery.p
        pagingItemsToSkip = pagingCurrent*pagingItemsPerPage-12
    }

    useEffect(() => {
        setpagingCurrentPage(pagingCurrent-1)    
    }, [pagingCurrent])

    useEffect(() => {
        if(inputString !== location.id) {
            setInputString(location.id)
        }
        if(location.id) {
            setTimeout(() => {
                // pushToHistory(searchString)
                push(`/news/q/${location.id}?p=1`)

            }, 500)        
        }
    }, [])    

    const handleInputChange = (event: SyntheticEvent) => {
        event.preventDefault()
        setInputString(event.target.value)
    }

    const performSearch = (event) => {
        event.preventDefault()
        // setSearchString(inputString)
        setTimeout(() => {
            // pushToHistory(searchString)
            push(`/news/q/${inputString}?p=1`)

        }, 1000)
    }

    return (
        <div className={style.newsByCategoryContainer}>
            <BackgroundImage
              url="https://images.ctfassets.net/7lhcm73ukv5p/6tcqDcocg2kARQbxjbGOP2/d328aad97866814c40ac4bff10b714f0/clouds-bg.jpg"
              repeat="no-repeat"
              size="contain"
              height={700}
              position="top center"
              className={style.newsBackground}
            >
                <Container>
                    <div className={style.search}>
                        <form onSubmit={performSearch}>
                            <input type="text" value={inputString} onChange={event => handleInputChange(event)} />
                            <button type="button" onClick={performSearch}>
                                search
                            </button>
                        </form>
                    </div>
                    <HeadingSmall>Searching for #{inputString} </HeadingSmall>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr ', gridColumnGap: '30px', gridRowGap: '30px', marginTop: '40px' }}>
                        <Pagination
                          currentPage={pagingCurrentPage}
                          pageCount={pageCount}
                          path={"q"}
                          query={location.id}
                          page="news"
                          subpage={"q"}
                          useQueryParameterForPaging
                        />
                    </div>                    
                    <ArticlesSearch locale={language} skip={pagingItemsToSkip} limit={12} query={location.id}>
                        {(collection) => {
                            if (collection.total) 
                                setPageCount(collection.total/pagingItemsPerPage)
                            return (
                                <div className={style.searchResults}>
                                    <p>Serch returnd # {collection.total}</p>
                                    {/* <p>Skipping first # {collection.skip}</p>
                                    <p>Currentpage # {pagingCurrent}</p>
                                    <p>page count # {collection.total/pagingItemsPerPage}</p> */}

                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '30px', gridRowGap: '30px', marginTop: '40px' }}>
                                        {_map(collection.items, (item, i) => (
                                            <Card key={i} filled newsItem={item} />
                                        ))}
                                    </div>
                                </div>
                            )
                        }}
                    </ArticlesSearch>
                </Container>
            </BackgroundImage>
        </div>
    )
}

export default SearchResult
