import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { push } from 'redux-first-router'
import _map from 'lodash/map'
import BackgroundImage from 'features/background-image'
import { getQuery } from 'lib/location/selectors'
import ArticlesByTag from 'queries/ArticlesByTag'
import NotFound from 'pages/not-found'
import { HeadingSmall } from 'layouts/typography'
import { Container } from 'layouts'
import Pagination from '../../../articles-category/components/pagination'
import { Card } from '../cards'
import BreadCrumbs from '../../../articles/components/articles/components/articles-breadcrumbs'
import style from './NewsByTag.module.scss'

interface Props {
    tag: string,
    language: string,
    // hasContent: boolean
}

const NewsByTag = ({ language, tag }: Props) => {
    const [pageCount, setPageCount] = useState(0)
    const [newsItemCount, setNewsItemCount] = useState(0)
    const [pagingCurrentPage, setpagingCurrentPage] = useState(0)
    const pagingQuery = useSelector((state) => getQuery(state))
     
    const pagingItemsPerPage = 4
    let pagingItemsToSkip = 0
    let pagingCurrent = 0

    if(pagingQuery) {
        pagingCurrent = parseInt(pagingQuery.p, 10)
        if(pagingCurrentPage === 0 || pagingCurrent === 1) 
        {
            pagingItemsToSkip = 0
        } else {
            pagingItemsToSkip = (pagingCurrent-1)*pagingItemsPerPage
        }
    }

    useEffect(() => {
        if(pagingCurrent >= 1)
            setpagingCurrentPage(pagingCurrent-1)
    }, [pagingCurrent])    
    
    // useEffect(() => {
    //     if(tag) {
    //         setTimeout(() => {
    //             push(`/news/t/${tag}?p=1`)

    //         }, 500)        
    //     }
    // }, [])   
    // console.log('current on page', pagingCurrentPage)
    // console.log('paging Items to skip', pagingItemsToSkip)
    return (
        <div className={style.newsByTagContainer}>
            <BackgroundImage
              url="https://images.ctfassets.net/7lhcm73ukv5p/6tcqDcocg2kARQbxjbGOP2/d328aad97866814c40ac4bff10b714f0/clouds-bg.jpg"
              repeat="no-repeat"
              size="contain"
              height={700}
              position="top center"
              className={style.newsBackground}
            >
                <Container className={style.wrapper}>
                    <BreadCrumbs first={'Home'} second={'news'} third={`#${ tag}`} />
                    <HeadingSmall>{newsItemCount} News tagged with #{tag}</HeadingSmall>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr ', gridColumnGap: '30px', gridRowGap: '30px', marginTop: '40px' }}>
                        <Pagination
                          currentPage={pagingCurrentPage}
                          pageCount={pageCount}
                          path={"t"}
                          query={tag}
                          page="news"
                          subpage={"t"}
                          useQueryParameterForPaging
                        />
                    </div>                    
                    <ArticlesByTag locale={language} skip={pagingItemsToSkip} limit={4} tag={tag}>
                        {(newsList) => {
                            // console.log('result', newsList)
                            if (newsList) {
                                if (newsList.total) 
                                    setPageCount(newsList.total/pagingItemsPerPage)
                                    setNewsItemCount(newsList.total)
                                return (
                                    <div className={style.newsByCategory}>
                                        {/* <Tags /> */}

                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '30px', gridRowGap: '30px', marginTop: '40px' }}>
                                            {_map(newsList.items, (item, i) => (
                                                <Card key={i} filled newsItem={item} />
                                            ))}
                                        </div>
                                    </div>
                                )
                            }
                            
                            return <NotFound />                         
                        }}

                    </ArticlesByTag>
                </Container>
            </BackgroundImage>
        </div>
    )
}

export default NewsByTag
