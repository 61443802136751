import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getPage, getSubpage, getLocationPayload } from 'lib/location/selectors'
import { findSectionByIdentifier } from 'lib/pages/api'
import BackgroundImage from 'features/background-image'
import NotFound from 'pages/not-found'
import PageWithSeo from 'features/page-with-seo'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import NewsByTag from './components/newsByTag'
import NewsArchive from './components/newsArchive'
import SearchResult from './components/searchResult'
import NewsDetails from './components/newsDetail'

import NewsPage from './components'

export default function NewsPageContainer() {

    const newsPage = useSelector((state) => getPage(state))
    const subpage = useSelector((state) => getSubpage(state))
    const location = useSelector((state) => getLocationPayload(state))
    const language = useSelector((state) => state.language)

    // console.log('news', newsPage)
    // console.log('sub page', subpage)
    // console.log('location', location)
    // console.log('location id', location.id)

    
    const NewsFrontpage = () => (
        <PageBySlugQuery slug={newsPage} locale={language}>
            {(page) => {
                let hasContent = false
                if (page) {
                    const sections = page.sectionsCollection.items
                    const heroSection = findSectionByIdentifier(sections, 'news-page-header')
                    const bottomSection = findSectionByIdentifier(sections, 'news-page-footer')

                    hasContent = true
                                        
                    return (
                        <PageWithSeo page={page} showLoading={false} hideSitename>
                            <BackgroundImage url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg">
                                <NewsPage hero={heroSection} promo={bottomSection} slug={newsPage} language={language} background={page.pageBackground?.url} hasContent />
                            </BackgroundImage>
                        </PageWithSeo>
                    )
                }

                return <NotFound />
            }}
        </PageBySlugQuery>
    )    
      
    // Show news by tag "/news/t/wormholes"
    if (subpage === 't' && location.id) {
        return (
            <BackgroundImage url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg">
                <NewsByTag language={language} tag={location.id} />
            </BackgroundImage>
        )
    }

    // Search in Artices/news by title or content  "/news/q/triglavian"
    if (subpage === 'q' && location.id && location.id.length > 0) {
        return (
            <BackgroundImage url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg">
                <SearchResult language={language} query={location.id} />
            </BackgroundImage>
        )
    }    

    // We are displaying a single article
    if (subpage === 'view') {
        return <NewsDetails />
    } else if (subpage === 'archive') {
        return (
            <BackgroundImage url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg">
                <NewsArchive language={language} />
            </BackgroundImage>
        )
    } else {
        return <NewsFrontpage />
    }
}
